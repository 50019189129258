import React, { useEffect, useState,  } from "react";
import {BrowserRouter as Router, Route, Redirect, useLocation} from "react-router-dom";
import Loadable from "react-loadable";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import {isAndroid} from "utils/utils";
import UpdateBrowserPopup from "components/Popups/UpdateBrowserPopup";
import {useFlags} from "flagsmith/react";
import { parse } from "query-string";
import dayjs from "dayjs";

import store from "./store";
import {
  getAllPermissions,
  getCompanyProfile,
  getUserProfile,
  getUserProfileByToken,
  logOut,
  refreshToken,
  setFeatureFlags,
} from './actions';
import Loading from "./components/Loading";
import Header from "./portals/Header";
import Footer from "./portals/Footer";
import CookiesAccept from "./components/CookiesAccept";
import ScrollToTop from "./utils/ScrollToTop";
import PressPopup from "./components/Popups/PressPopup";
import ShowStandardMessage from "./components/ShowStandardMessage";
// eslint-disable-next-line import/order
import RequestReviewPopup from "./components/Popups/RequestReview";

import "./scss/theme_v1/fonts.scss";
import "./scss/theme_v2/fonts.scss";

import "./scss/theme_v2/general.scss";
import "./scss/theme_v1/general.scss";
import Page from "./components/Page/Page";
import {FLAGS_LIST} from "./data/featureFlags";
import {useWindowSize} from "./utils/hooks";
import {RemoveTrailingSlash} from "./RemoveTrailingSlash";

import { LastLocationProvider } from 'react-router-last-location';

import { LogOutAction } from "./utils/logout";
import { isFormulasPage } from "./utils/isFormulasPage";

import { closeSnackbar, toggleSnackbar as toggleSnackBar } from 'actions/snackbar';
import { connect, useDispatch } from "react-redux";

import SnackBar from "./components/SnackBar";

import { Helmet } from 'react-helmet';

import { getMaintenanceMessages } from './actions/maintenanceMessages';
import OverdueInvoicePopup from './components/Popups/OverdueInvoicePopup';

const PrivateRoute = ({ children, redirectTo }) => {
  const user = JSON.parse(sessionStorage.getItem("user-info-for-verified"));
  let isAuthenticated = user?.access;

  const path = window.location.pathname;
  const search = window.location.search;
  const query = parse(search);

  if (query.from_superadmin && query.refresh_token) {
    if (query.show_overdue_invoice_modal) {
      const overdueData = {
        overdue: true,
        endDate: query.overdue_invoice_end_date
      };

      window.sessionStorage.setItem('Overdue', JSON.stringify(overdueData));
    }

    sessionStorage.removeItem('user-info-for-verified');
    localStorage.removeItem('last-seen');
    localStorage.removeItem('sessionTimeStamp');
    window.localStorage.setItem('CREDENTIALS_FLUSH', Date.now().toString());
    window.localStorage.removeItem('CREDENTIALS_FLUSH');

    store.dispatch(getUserProfileByToken(query.refresh_token)).then((data) => {
      if (data) {
        localStorage.setItem('last-seen', JSON.stringify(Date.now()));
        localStorage.setItem('login-timestamp', dayjs());
        localStorage.setItem('sessionTimeStamp', JSON.stringify(Date.now()));

        window.localStorage.setItem(
          'CREDENTIALS_SHARING',
          JSON.stringify(data),
        );
        window.localStorage.removeItem('CREDENTIALS_SHARING');

        window.location.href = path;
      } else {
        return <Redirect to="/accounts/login?type=sso&next=/sa/products" />
      }
    })
  }

  return isAuthenticated ? children : <Redirect to={redirectTo} />;
};

const SSOLoginRoute = () => {
  const search = window.location.search;
  const query = parse(search);

  if (query.token) {
    store.dispatch(getUserProfileByToken(query.token)).then((data) => {
      if (data) {
        if (process.env.REACT_APP_SEGMENT_KEY?.length && data?.user?.id) {
          window.analytics && window.analytics.identify(data.user.id, {
            $distinct_id: data.user.id,
            user_id: data.user.id,
            $email: data.user.email,
            email: data.user.email,
            name: data.user.name,
            $name: data.user.name,
            company: {
              id: data?.user?.company?.id,
              company_id: data?.user?.company?.id,
              name: data?.user?.company?.name,
              company_name: data?.user?.company?.name,
            },
            environment: 'client-side'
          }, {}, () => {
            // segmentsTrackWithCallback({
            //   user: data.user,
            //   name: 'User Logged In',
            //   description: 'User login',
            //   track_fields: {}
            // })
          })
        }

        localStorage.setItem('last-seen', JSON.stringify(Date.now()));
        localStorage.setItem('login-timestamp', dayjs());
        localStorage.setItem('sessionTimeStamp', JSON.stringify(Date.now()));
        const path = window.sessionStorage.getItem('path')
        const formulas = path !== '/' && path?.length ? path : '/fa/formulas'
        const rawMaterial = path !== '/' && path?.length ?  path : '/fa/raw-material'
        window.location.href = !data?.user?.permissions?.find((item) => item.name === 'supplier_basic_account')
          ? formulas
          : rawMaterial
        sessionStorage.removeItem('path')
      } else {
        return <Redirect to="/accounts/login?type=sso&next=/sa/products" />
      }
    })
  } else if (query.message) {
    return <Redirect to={`/accounts/login?type=sso&next=/sa/products&message=${query.message}`} />
  }

  setTimeout(() => {
    if (!query.token && !query.message) {
      window.location.href = 'accounts/login?type=sso&next=/sa/products'
    }
  }, 1000);

  return <Loading isLoading />
}

const AsyncSingleProduct = Loadable({
  loader: () => import("./pages/SingleProduct"),
  loading: Loading,
});

// Tmp before waiting design for tablet
const AsyncSingleProductTablet = Loadable({
  loader: () => import("./pages/SingleProduct/tablet"),
  loading: Loading,
});

const AsyncBrandsLanding = Loadable({
  loader: () => import("./pages/BrandsNewLanding"),
  loading: Loading,
});

const AsyncComparingLanding = Loadable({
  loader: () => import("./pages/ComparingLanding"),
  loading: Loading,
});

const AsyncCareersLanding = Loadable({
  loader: () => import("./pages/CareersLanding"),
  loading: Loading,
});

const AsyncBookDemoLanding = Loadable({
  loader: () => import("./pages/BookDemoLanding"),
  loading: Loading,
});

const AsyncRetailersLanding = Loadable({
  loader: () => import("./pages/RetailersLanding"),
  loading: Loading,
});

const AsyncRetailLanding = Loadable({
  loader: () => import("./pages/RetailLanding"),
  loading: Loading,
});

const AsyncIngredientSuppliersLanding = Loadable({
  loader: () => import("./pages/IngredientSuppliersLanding"),
  loading: Loading,
});

const AsyncIngredientContractmanufacturersSuppliersLanding = Loadable({
  loader: () => import("./pages/ContractmanufacturersLanding"),
  loading: Loading,
});

const AsyncRegulatoryServicesLanding = Loadable({
  loader: () => import("./pages/RegulatoryServicesLanding"),
  loading: Loading,
});

const AsyncBrandsAssignBilling = Loadable({
  loader: () => import("./pages/BrandsAssignBilling"),
  loading: Loading,
});

const AsyncTrademark = Loadable({
  loader: () => import("./pages/Trademark"),
  loading: Loading,
});

const AsyncAffidavit = Loadable({
  loader: () => import("./pages/Affidavit"),
  loading: Loading,
});

const AsyncCreateRetailer = Loadable({
  loader: () => import("./pages/CreateRetailer"),
  loading: Loading,
});

const AsyncSingleIngredient = Loadable({
  loader: () => import("./pages/SingleIngredient"),
  loading: Loading,
});

const AsyncArchiveProducts = Loadable({
  loader: () => import("./pages/ArchiveProducts"),
  loading: Loading,
});

const AsyncSignIn = Loadable({
  loader: () => import("./pages/SignIn"),
  loading: Loading,
});

const AsyncVerify = Loadable({
  loader: () => import("./pages/Verify"),
  loading: Loading,
});

const AsyncSignUp = Loadable({
  loader: () => import("./pages/SignUp"),
  loading: Loading,
});

const AsyncCompletePayment = Loadable({
  loader: () => import("./pages/SignUp/components/CompletePayment"),
  loading: Loading,
});

const AsyncPartnerLanding = Loadable({
  loader: () => import("./pages/Partner"),
  loading: Loading,
});

const AsyncReview = Loadable({
  loader: () => import("./pages/Article/Review"),
  loading: Loading,
});

const AsyncAboutNew = Loadable({
  loader: () => import("./pages/AboutNew"),
  loading: Loading,
});

const AsyncGFIGuide = Loadable({
  loader: () => import("./pages/GFI-Guide"),
  loading: Loading,
});

const AsyncBlogLanding = Loadable({
  loader: () => import("./pages/BlogLanding"),
  loading: Loading,
});

const AsyncSearch = Loadable({
  loader: () => import("./pages/Search"),
  loading: Loading,
});

const AsyncArticle = Loadable({
  loader: () => import("./pages/Article/Regular"),
  loading: Loading,
});

const AsyncFAQ = Loadable({
  loader: () => import("./pages/FAQ"),
  loading: Loading,
});

const AsyncFAQNew = Loadable({
  loader: () => import("./pages/FAQNew"),
  loading: Loading,
});

const AsyncPrivacyPolicy = Loadable({
  loader: () => import("./pages/PrivacyPolicy"),
  loading: Loading,
});

const AsyncSaasAgreement = Loadable({
  loader: () => import("./pages/SaasAgreement"),
  loading: Loading,
});

const AsyncTermAndConditions = Loadable({
  loader: () => import("./pages/TermsAndConditions"),
  loading: Loading,
});

const AsyncEmailSignUp = Loadable({
  loader: () => import("./pages/EmailSignUp"),
  loading: () => Loading,
});

const AsyncSubscriptionPopup = Loadable({
  loader: () => import("./components/Popups/SubscriptionPopup"),
  loading: () => null,
});

const AsyncAndroidNotifyPopup = Loadable({
  loader: () => import("./components/Popups/AndroidNotifyPopup"),
  loading: () => null,
});

const AsyncShopByBrand = Loadable({
  loader: () => import("./pages/ShopByBrand"),
  loading: () => null,
});

const AsyncWebinar = Loadable({
  loader: () => import("./pages/Webinar"),
  loading: () => null,
});

/* B2B Pages */

const B2BHomepage = Loadable({
  loader: () => import("./pages-b2b/Homepage"),
  loading: () => Loading,
});

const B2BFormulator = Loadable({
  loader: () => import("./pages-b2b/Formulator"),
  loading: () => Loading,
});

const B2BAmplify = Loadable({
  loader: () => import("./pages-b2b/Amplify"),
  loading: () => Loading,
});

const B2BExperts = Loadable({
  loader: () => import("./pages-b2b/Experts"),
  loading: () => Loading,
});

const B2BExpertsNew = Loadable({
  loader: () => import("./pages-b2b/ExpertsNew"),
  loading: () => Loading,
});

const AsyncPDFGenerator = Loadable({
  loader: () => import("./pages/PDFGenerator"),
  loading: Loading,
});

const AsyncPDFGeneratorFormulator = Loadable({
  loader: () => import("./pages/PDFGeneratorFormulator"),
  loading: Loading,
});

const AsyncCertificationGenerator = Loadable({
  loader: () => import("./pages/CertificationGenerator"),
  loading: Loading,
});

const AsyncPdfGeneratorIngredient = Loadable({
  loader: () => import("./pages/PdfGeneratorIngredient"),
  loading: Loading,
});

const AsyncPdfGeneratorCompliance = Loadable({
  loader: () => import("./pages/PdfGeneratorCompliance"),
  loading: Loading,
});

const AsyncPdfMaterialDataSheet = Loadable({
  loader: () => import("./pages/PDFMaterialDataSheet"),
  loading: Loading,
});

const AsyncPdfCorrectiveActions = Loadable({
  loader: () => import("./pages/PDFCorrectiveActions"),
  loading: Loading,
});

const AsyncFormulatorDocuments = Loadable({
  loader: () => import("./pages/FormulatorDocuments"),
  loading: Loading,
});

const AsyncFormulatorRMPublish = Loadable({
  loader: () => import("./pages/FormulatorRawMaterialsPublish"),
  loading: Loading,
});

const AsyncFormulatorSPLGenerate = Loadable({
  loader: () => import("./pages/FormulatorSPLGenerate"),
  loading: Loading,
});

const AsyncFormulatorAdmin = Loadable({
  loader: () => import("./pages/FormulatorAdmin"),
  loading: Loading,
});

const AsyncFormulatorAdminProfile = Loadable({
  loader: () => import("./pages/FormulatorAdminProfile"),
  loading: Loading,
});

const AsyncFormulatorAdminEditFormulas = Loadable({
  loader: () => import("./pages/FormulatorAdminEditFormulas"),
  loading: Loading,
});

const AsyncFormulatorFormulasView = Loadable({
  loader: () => import("./pages/FormulatorFormulasView"),
  loading: Loading,
});

const AsyncFormulatorAdminViewPartners = Loadable({
  loader: () => import("./pages/FormulatorAdminViewPartners"),
  loading: Loading,
});

const AsyncFormulatorPartnershipsFormula = Loadable({
  loader: () => import("./pages/FormulatorPartnershipsFormula"),
  loading: Loading,
});

const AsyncFormulatorInsights = Loadable({
  loader: () => import("./pages/Cara/FormulatorAdminInsights"),
  loading: Loading,
});

const AsyncCaraDoppelgangers = Loadable({
  loader: () => import("./pages/Cara/CaraDoppelgangers"),
  loading: Loading,
});


Modal.setAppElement("body");

const cookies = new Cookies(),
  { sessionStorage } = window;

const user = JSON.parse(sessionStorage.getItem("user-info-for-verified"));

const events = ['click', 'load', 'scroll'];
const SESSION_TIMEOUT_VALUE = 3600000;

const App = (props) => {
  const flags = useFlags(FLAGS_LIST);
  const [showHeader, setShowHeader] = useState(
      window.location.pathname.indexOf("pdf-generator") === -1 &&
      window.location.pathname.indexOf("certification-generate") === -1 &&
      window.location.pathname.indexOf("pdf-material-data-sheet") === -1 &&
      window.location.pathname.indexOf("pdf-corrective-actions") === -1 &&
      window.location.pathname.indexOf("pdf-compliance-report") === -1 &&
      window.location.pathname.indexOf("pdf-formulator-generator") === -1 &&
      window.location.pathname.indexOf("/payment-complete") === -1 &&
      window.location.pathname.indexOf("/demo") === -1
  );
  const isFaPage =
    window.location.pathname.includes('/fa') &&
    !window.location.pathname.includes('faq') &&
    !window.location.pathname.includes('face');



  store.dispatch(setFeatureFlags(flags));
  const overdueData = JSON.parse(window.sessionStorage.getItem('Overdue'))
  const [ updateBrowser, setUpdateBrowser] = useState(false);
  const [showOverdueModal, setShowOverdueModal] = useState(false)
  const windowSize = useWindowSize();
  const search = window.location.search;
  const query = parse(search);
  const lastSeen = localStorage.getItem('last-seen');
  const currentUser = JSON.parse(sessionStorage.getItem("user-info-for-verified"));
  const currentUserAccess = currentUser?.access;
  const SUPPLIER_BASIC =
    !!props?.companyPermissions?.find((item) => item.name === 'supplier_basic_account');
  const COMPOUND_FORMULATOR =
    !!props?.companyPermissions?.find((item) => item.name === 'compound_formulator');
  const { condition, msg, showSnackbarError } = props;

  const handleLogout = async () => {
    await store.dispatch(logOut({ auto_logout: true }));
    LogOutAction();
    window.sessionStorage.setItem('email', props.user?.email);
  };

  if (
    !!user &&
    !query.from_superadmin &&
    (!lastSeen || (Date.now() - lastSeen) > 90000)
  ) {
    handleLogout()
  }
  // else if (query.from_superadmin) {
  //   localStorage.setItem('last-seen', JSON.stringify(Date.now()));
  //   localStorage.setItem('sessionTimeStamp', JSON.stringify(Date.now()));
  //   window.history.pushState({}, '', window.location.pathname);
  // }
  useEffect(() => {
    if (props.user?.email) {
      window.sessionStorage.setItem('email',  props.user?.email)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.user?.email])

  useEffect(() => {
    if (overdueData) {
      setTimeout(() => {
        setShowOverdueModal(overdueData.overdue)
      },2500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let refreshTokenInterval = setInterval(() => {
    refreshToken();
    const user = JSON.parse(sessionStorage.getItem("user-info-for-verified"));

    if (user?.refresh) {
      store.dispatch(refreshToken());
    } else {
      clearInterval(refreshTokenInterval);
    }
  }, 600000);


  const observeUrlChange = () => {
    let oldHref = document.location.href;
    const body = document.querySelector("body");
    const observer = new MutationObserver(mutations => {
      mutations.forEach(() => {
        if (oldHref !== document.location.href) {
          oldHref = document.location.href;
          setShowHeader(
              window.location.pathname.indexOf("pdf-generator") === -1 &&
              window.location.pathname.indexOf("certification-generate") === -1 &&
              window.location.pathname.indexOf("pdf-material-data-sheet") === -1 &&
              window.location.pathname.indexOf("pdf-corrective-actions") === -1 &&
              window.location.pathname.indexOf("pdf-compliance-report") === -1 &&
              window.location.pathname.indexOf("pdf-formulator-generator") === -1 &&
              window.location.pathname.indexOf("/payment-complete") === -1 &&
              window.location.pathname.indexOf("/demo") === -1)
        }
      });
    });
    observer.observe(body, { childList: true, subtree: true });
  };

  window.onload = observeUrlChange;

  useEffect(
    () => {
      events.forEach((event) => {
        window.addEventListener(event, () => {
          const isLoggedIn = !!JSON.parse(sessionStorage.getItem('user-info-for-verified'));
          const currentSessionTimeStamp = JSON.parse(localStorage.getItem('sessionTimeStamp'));
          const diffTime = Date.now() - currentSessionTimeStamp;

          if (isLoggedIn) {
            if (currentSessionTimeStamp && diffTime > 5000) {
              if (diffTime < SESSION_TIMEOUT_VALUE) {
                localStorage.setItem('sessionTimeStamp', JSON.stringify(Date.now()));
              } else {
                handleLogout()
              }
            }
          }
        });
      });

      if (!!user && lastSeen && (Date.now() - lastSeen) < 90000) {
        localStorage.setItem('last-seen', JSON.stringify(Date.now()));
      }

      const updateLastSeen = setInterval(() => {
        const isLoggedIn = !!JSON.parse(sessionStorage.getItem('user-info-for-verified'));
        const sessionTimeStamp = JSON.parse(localStorage.getItem('sessionTimeStamp'));

        if (isLoggedIn) {
          if (!sessionTimeStamp || (Date.now() - sessionTimeStamp) > SESSION_TIMEOUT_VALUE) {
            handleLogout()
          } else {
            localStorage.setItem('last-seen', JSON.stringify(Date.now()));
          }
        }
      }, 60000);

      return () => {
        clearInterval(updateLastSeen);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastSeen]
  );

  useEffect(() => {
    if (currentUser && !props?.companyPermissions && !props.allPermissionsLoading) {
      store.dispatch(getUserProfile());
      store.dispatch(getAllPermissions(false, isFormulasPage()));
      store.dispatch(getCompanyProfile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const dispatch = useDispatch();

  useEffect(() => {
    if (!!currentUserAccess) {
      dispatch(getMaintenanceMessages());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserAccess])

  useEffect(
    ()=> {
      const isLoggedIn = !!JSON.parse(sessionStorage.getItem('user-info-for-verified'));
      const { detect } = require('detect-browser');
      const browser = detect();

      if (browser.name === 'firefox') {
        const html = document.querySelector('html');

        html.style.scrollbarColor = '#7F7F7F #F2F2F2'; // Fix Firefox scrollbar color customization.
      }

      if (query?.showSnackBar === 'true') {
        dispatch(toggleSnackBar(
          true,
          'Your account is not active. Please contact your account admin.',
          true,
          true
        ));
        window.history.replaceState({}, '', '/');
      }

      if (isLoggedIn) {
        if (
          (browser.name === 'chrome' && Number(browser.version.split('.')[0]) < 49) ||
          (browser.name === 'firefox' && Number(browser.version.split('.')[0]) < 31 ) ||
          (browser.name === 'Edge' && Number(browser.version.split('.')[0]) < 13) ||
          (browser.name === 'trident' && Number(browser.version.split('.')[0]) < 11) ||
          (browser.name === 'msie' && Number(browser.version.split('.')[0]) < 11) ||
          (browser.name === 'safari' && Number(browser.version.split('.')[0]) < 9)
        )
          {
            setTimeout(() => {
              setUpdateBrowser(true)
              }, 1500);
          }
      }

      //Fix for 3d party cookies
      [...document.querySelectorAll('img')].map((x) => {if(x.src?.includes('liadm')) {x.style.display = "none" }})

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { featureFlags } = props;

  if (!showHeader) {
    const gfpAppWrap = document.getElementsByClassName('gfp-app-wrap');
    gfpAppWrap[0].classList.remove('-fixed');
  }

  const RemoveTrailingSlash = ({...rest}) => {
    const location = useLocation();

    if (location.pathname.match('/.*/$')) {
      return <Redirect replace {...rest} to={{
        pathname: location.pathname.replace(/\/+$/, ""),
        search: location.search
      }}/>
    } else return null
  }

  const getLinkPrefix = () => process.env.REACT_APP_CDN_STATIC_URL ? process.env.REACT_APP_CDN_STATIC_URL : '/static/'

  return (
    <Router>
      <LastLocationProvider>
      <Page>
        <React.Fragment>
          <Helmet>
            <link rel="apple-touch-icon" sizes="180x180" href={`${getLinkPrefix()}img/favicon/apple-touch-icon.png`} />
            <link rel="icon" type="image/png" sizes="32x32" href={`${getLinkPrefix()}img/favicon/favicon-32x32.png`} />
            <link rel="icon" type="image/png" sizes="16x16" href={`${getLinkPrefix()}img/favicon/favicon-16x16.png`} />
            <link rel="manifest" href={`${getLinkPrefix()}img/favicon/site.webmanifest`} />
            <link rel="mask-icon" href={`${getLinkPrefix()}img/favicon/safari-pinned-tab.svg`} color="#5bbad5" />
          </Helmet>
          <RemoveTrailingSlash />
          {showHeader && <Header updateBrowser={updateBrowser} windowSize={windowSize} />}
          {updateBrowser
            ? <div className={'disabled'}></div>
            : ''
          }
           {showHeader && updateBrowser
            ? <UpdateBrowserPopup updateBrowser={updateBrowser} />
            : ''
          }

          <Route path="/demo" component={AsyncBookDemoLanding} />
          <Route path="/careers" component={AsyncCareersLanding} />
          <Route path="/compare" component={AsyncComparingLanding} />

          <Route path="/brands" component={AsyncBrandsLanding} />
          <Route path="/retail" component={AsyncRetailersLanding} />
          <Route path="/ingredientsuppliers" component={AsyncIngredientSuppliersLanding} />
          <Route path="/regulatoryservices" component={AsyncRegulatoryServicesLanding} />
          <Route path="/contractmanufacturers" component={AsyncIngredientContractmanufacturersSuppliersLanding} />
          <Route path="/retailers" component={AsyncRetailLanding} />
          <Route path="/trademark" component={AsyncTrademark} />
          <Route path="/affidavit" component={AsyncAffidavit} />
          <Route path="/faq" component={AsyncFAQNew} />
          <Route path="/consumers/faq" component={AsyncFAQ} />
          <Route path="/f_privacy_policy" component={AsyncPrivacyPolicy} />
          <Route path="/f_saas_agreement" component={AsyncSaasAgreement} />
          <Route
            path="/f_terms_and_conditions"
            component={AsyncTermAndConditions}
          />
          <Route
            path="/ingredients/:ingredientSlug"
            component={AsyncSingleIngredient}
          />
          <Route
            path="/certification-generate-brand/:id"
            component={AsyncCertificationGenerator}
          />
          <Route
            path="/certification-generate-products/:ids"
            component={AsyncCertificationGenerator}
          />
          {/*<Route*/}
          {/*  path="/shop/:category?/"*/}
          {/*  exact*/}
          {/*  component={AsyncArchiveProducts}*/}
          {/*/>*/}

          <Route path="/accounts/login" component={AsyncSignIn} />
          <Route path="/accounts/verify" component={AsyncVerify} />
          <Route path="/accounts/registration" component={AsyncSignUp} />
          <Route path="/payment-complete" component={AsyncCompletePayment} />
          <Route exact path="/brands_register">
            <Redirect to="/" />
          </Route>
          <Route exact path="/good_face_index">
            <Redirect to="/good_face_approved" />
          </Route>
          <Route
            path="/brands_assign_billing"
            component={AsyncBrandsAssignBilling}
          />

          <Route path="/create-retailer" component={AsyncCreateRetailer} />

          <Route path="/search" component={AsyncSearch} />
          <Route path="/shop_by_brand" component={AsyncShopByBrand} />

          <Route path="/" exact component={B2BHomepage} />
          <Route exact path="/consumer">
            <Redirect to="/" />
          </Route>
          <Route exact path="/app">
            <Redirect to="/" />
          </Route>
          {/*<Route path="/consumer" exact component={ConsumerHome}/>*/}
          <Route path="/partner" component={AsyncPartnerLanding} />
          {/*<Route path="/app" component={AsyncAppLanding}/>*/}
          <Route path="/reviews/:slug" component={AsyncReview} />
          <Route path="/articles/:slug" component={AsyncArticle} />
          <Route path="/about" component={AsyncAboutNew} />
          <Route path="/good_face_approved" component={AsyncGFIGuide} />
          <Route path="/blog" component={AsyncBlogLanding} />
          <Route path="/email-signup" component={AsyncEmailSignUp} />
          <Route path="/clean-beauty-retail-webinar" component={AsyncWebinar} />

          <Route path="/pdf-generator/:slug" component={AsyncPDFGenerator} />
          <Route
            path="/pdf-formulator-generator/:slug"
            component={AsyncPDFGeneratorFormulator}
          />
          <Route
            path="/pdf-generator-ingredient/:slug"
            component={AsyncPdfGeneratorIngredient}
          />
          <Route
            path="/pdf-compliance-report/:slug"
            component={AsyncPdfGeneratorCompliance}
          />
          <Route
            path="/pdf-material-data-sheet/:slug"
            component={AsyncPdfMaterialDataSheet}
          />
          <Route
            path="/pdf-corrective-actions/:slug"
            component={AsyncPdfCorrectiveActions}
          />

          <Route
              path="/sso"
              render={(props) => (
                  <SSOLoginRoute path="/sso" />
              )}
          />

          {/* B2B Innovation Platform Pages */}
          <Route path="/formulator" component={B2BFormulator} />
          <Route path="/amplify" component={B2BAmplify} />
          <Route path="/experts">
            <Redirect to="/consumerstudies" />
          </Route>
          <Route path="/consumerstudies" component={B2BExpertsNew} />
          <Route
            path="/fa/:slug/:id?/:name?"
            render={(props) => (
              <PrivateRoute path="/fa/:slug">
                <AsyncFormulatorAdmin {...props} windowSize={windowSize} />
              </PrivateRoute>
            )}
          />
          <Route
            path="/fa/formulas/:id"
            render={(props) => (
              <PrivateRoute path="/fa/:slug">
                <AsyncFormulatorAdmin {...props} />
              </PrivateRoute>
            )}
          />

          <Route
            path="/fa-profile/:slug"
            render={(props) => (
              <PrivateRoute path="/fa/:slug">
                <AsyncFormulatorAdminProfile {...props} />
              </PrivateRoute>
            )}
          />

          <Route
              path="/fa-documents/:id"
              render={(props) => (
                  <PrivateRoute path="/fa/:slug">
                    <AsyncFormulatorDocuments {...props} />
                  </PrivateRoute>
              )}
          />
          <Route
              path="/raw-material-publish"
              render={(props) => (
                  <PrivateRoute path="/fa/:slug">
                    <AsyncFormulatorRMPublish {...props} />
                  </PrivateRoute>
              )}
          />

          <Route
            path="/spl-generate"
            render={(props) => (
              <PrivateRoute path="/fa/:slug">
                <AsyncFormulatorSPLGenerate {...props} />
              </PrivateRoute>
            )}
          />


          <Route
            path="/fa-formulas-edit/:id/:pageType"
            render={(renderProps) =>
              (props.user && props.companyPermissions) && SUPPLIER_BASIC ? (
                <Redirect
                  to={
                    COMPOUND_FORMULATOR
                      ? "/fa/raw-material"
                      : "/fa/reports"
                  }
                />
              ) : (
                <PrivateRoute path="/fa/:slug">
                  {featureFlags?.my_formulas_new_api?.enabled
                    ? <AsyncFormulatorFormulasView {...renderProps} />
                    : <AsyncFormulatorAdminEditFormulas {...renderProps} />
                  }
                </PrivateRoute>
              )
            }
          />

          <Route
              path="/fa-formulas-insight"
              render={(props) => (
                  <PrivateRoute path="/fa/:slug">
                    <AsyncFormulatorInsights {...props} />
                  </PrivateRoute>
              )}
          />

          <Route
              path="/fa-reverse-engineering"
              render={(props) => (
                  <PrivateRoute path="/fa/:slug">
                    <AsyncFormulatorInsights {...props} />
                  </PrivateRoute>
              )}
          />

          <Route
              path="/fa-doppelgangers"
              render={(props) => (
                  <PrivateRoute path="/fa/:slug">
                    <AsyncCaraDoppelgangers {...props} />
                  </PrivateRoute>
              )}
          />

          <Route
            path="/fa-partner-products/:partnerIid/:pageType/:id"
            render={(renderProps) =>
              (props.user && props.companyPermissions) && SUPPLIER_BASIC ? (
                <Redirect
                  to={
                    COMPOUND_FORMULATOR
                      ? "/fa/raw-material"
                      : "/fa/reports"
                  }
                />
              ) : (
                <PrivateRoute path="/fa/:slug">
                  {featureFlags?.new_partners_api?.enabled
                      ? <AsyncFormulatorPartnershipsFormula {...renderProps} />
                      : <AsyncFormulatorAdminViewPartners {...renderProps} />}
                </PrivateRoute>
              )
            }
          />

          <Route
            render={({ location }) =>
              location.pathname !== "/search" && location.pathname !== "/raw-material-publish" && location.pathname !== "/spl-generate" && showHeader && <Footer />
          }
          />
          {showOverdueModal &&
            <OverdueInvoicePopup
              isOpen={showOverdueModal}
              endDate={overdueData.endDate}
              closePopup={() => {
                setShowOverdueModal(false)
                window.sessionStorage.removeItem('Overdue')
              }}
            />}
          <PressPopup />
          <RequestReviewPopup />
          <ScrollToTop />

          {!cookies.get("gfp-cookies-accepted") && showHeader && !isFaPage && window.location.pathname.indexOf("/raw-material-publish") === -1 && window.location.pathname.indexOf("/spl-generate") === -1 && (
            <CookiesAccept />
          )}

          <ShowStandardMessage />

          {!cookies.get("gfp-subscribed") &&
            !cookies.get("gfp-subscription-popup") &&
            showHeader && <AsyncSubscriptionPopup />}

          {isAndroid() && <AsyncAndroidNotifyPopup />}

          {condition && (
            <SnackBar
              hideSnackBar={() => store.dispatch(closeSnackbar())}
              showSnackbar={condition}
              message={msg}
              showSnackbarError={showSnackbarError}
            />
          )}
        </React.Fragment>
      </Page>
      </LastLocationProvider>
    </Router>
  );
};

export default connect((state) => ({
  user: state.formulator.user,
  companyPermissions: state.formulator.companyPermissions,
  msg: state.snackbar.msg,
  condition: state.snackbar.condition,
  showSnackbarError: state.snackbar.showSnackbarError,
  allPermissionsLoading: state.formulator.allPermissionsLoading,
  featureFlags: state.user.featureFlags
}))(App);
