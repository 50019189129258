/* eslint-disable no-useless-escape */

import dayjs from "dayjs";
import { scroller } from "react-scroll";

import config from '../config';
import { NONE } from '../pages/FormulatorAdmin/FormulatorRawMaterials/consts';

const urlRegEx = new RegExp(
  // eslint-disable-next-line max-len
  '^(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})',
);
const MAX_URL_LENGTH = 2083;

export function isProduction() {
  return process.env.NODE_ENV === 'production';
}

export function isDevelopment() {
  return process.env.NODE_ENV === 'development';
}

// Validation
export function validateEmail(email) {
  // eslint-disable-next-line max-len
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
}

export function chunkArray(myArray, chunk_size) {
  let arrayLength = myArray.length,
    tempArray = [];

  for (let index = 0; index < arrayLength; index += chunk_size) {
    const myChunk = myArray.slice(index, index + chunk_size);
    tempArray.push(myChunk);
  }

  return tempArray;
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';

  return s.charAt(0).toUpperCase() + s.slice(1)
};

export const scrollElementIntoView = (element) => {
  const finalOffset = element.getBoundingClientRect().top;

  window.parent.scrollTo({
    top: finalOffset,
  });
};

export function getStripeApiKey() {
  return isBetaServer() ? config.stripeApiKeyTest : config.stripeApiKey;
}

export function getApiBase() {
  if (!isProduction()) {
    return config.apiBaseUrlDevelopment;
  }

  return isBetaServer()
    ? config.apiBaseUrlBeta
    : isDevServer()
    ? config.apiBaseUrlDev
    : isStgServer()
    ? config.apiBaseUrlStg
    : isPilotServer()
    ? config.apiBaseUrlPilot
    : config.apiBaseUrlProduction;
}

export function isBetaServer() {
  const {hostname} = window.location;

  return hostname === 'beta.thegoodfaceproject.com' || hostname === 'www.beta.thegoodfaceproject.com';
}

export function isDevServer() {
  const {hostname} = window.location;

  return hostname === 'app.dev.thegoodfaceproject.com' || hostname === 'www.app.dev.thegoodfaceproject.com';
}

export function isStgServer() {
  const {hostname} = window.location;

  return hostname === 'app.stg.thegoodfaceproject.com' || hostname === 'www.app.stg.thegoodfaceproject.com';
}

export function isPilotServer() {
  const {hostname} = window.location;

  return hostname === 'app.pilot.thegoodfaceproject.com' || hostname === 'www.app.pilot.thegoodfaceproject.com';
}

export function isLocalServer() {
  const {hostname} = window.location;

  return hostname === '127.0.0.1' || hostname === 'localhost';
}

export function getDefaultImageLink(link) {
  return `${process.env.REACT_APP_BACKEND_URL}${link}`;
}

export function isAndroid() {
  const ua = navigator.userAgent.toLowerCase();

  return ua.indexOf("android") > -1;
}

export const onEnterPress = (event, func) => {
  if (event.keyCode === 13) {
    func();
  }
}

export const scrollToPagination = () => {
  scroller.scrollTo('pagination-section', {
    smooth: false,
    offset: 0
  });
}

export const validateUrl = (url) => {
  return url.length < MAX_URL_LENGTH && urlRegEx.test(url);
};

export const redirectToPage = (url, history, newTab = false) => {
  if (newTab) {
    window.open(url, "_blank");
  } else {
    history.push({
      pathname: url,
      state: { prevPath: history.location.pathname + history.location.search }
    })
  }
}

export const formulaFormatDateDigital = (date) => {
  const currentDate = new Date(date);

  return `${currentDate.getDate()}.${('0' + (currentDate.getMonth() + 1)).slice(-2)}.${currentDate.getFullYear()}`
}

export function formatDateMonthDayYear(date) {
  const currentDate = new Date(date);

  return `${('0' + (currentDate.getMonth() + 1)).slice(
    -2,
  )}.${currentDate.getDate()}.${currentDate.getFullYear()}`;
}

export function formatDateDayCustomMonthYear(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
  const year = String(date.getFullYear()).slice(-2);

  return `${day}-${month}-${year}`;
}

export function formatDate(date, format = 'YYYY-MM-DD') {
  if (!date) {
    return '';
  }

  const YYYY = date.getFullYear();
  const MM = String(date.getMonth() + 1).padStart(2, '0');
  const mmm = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
  const MMM = new Intl.DateTimeFormat('en', { month: 'short' }).format(date).toUpperCase();
  const DD = String(date.getDate()).padStart(2, '0');

  const dateObj = {
    'YYYY-MM-DD': `${YYYY}-${MM}-${DD}`,
    'DD.MM.YYYY': `${DD}.${MM}.${YYYY}`,
    'DD-MMM-YYYY': `${DD}-${mmm}-${YYYY}`,
    'DD-mmm-YY': `${DD}-${mmm}-${String(YYYY).slice(-2)}`,
    'DD-MMM-YY': `${DD}-${MMM}-${String(YYYY).slice(-2)}`,
  };

  return dateObj[format];
}

export function parseDateAsUTC(dateString) {
  const [year, month, day] = dateString.split('-').map(Number);

  return new Date(year, month - 1, day);
}

export function formatDateCustomMonthAndTime(date) {
  const currentDate = new Date(date);

  return `${formatDateDayCustomMonthYear(currentDate)} ${
    ('0' + (currentDate.getHours())).slice(-2)
  }:${
    ('0' + (currentDate.getMinutes())).slice(-2)
  }:${
    ('0' + (currentDate.getSeconds())).slice(-2)
  }`;
}

export function getApiBaseSocketUrl() {
  if (!isProduction()) {
    return config.apiBaseSocketUrlDevelopment;
  }

  return isBetaServer() ?
      config.apiBaseSocketUrlBeta :
      isDevServer() ? config.apiBaseSocketUrlDev :
          isStgServer() ? config.apiBaseSocketUrlStg :
              isPilotServer() ? config.apiBaseSocketUrlPilot :
              config.apiBaseSocketUrlProduction;
}

export function getApiBaseFeatureFlagsKey() {
  if (isLocalServer()) {
    return config.featureFlagsKeyLocalhost;
  }

  if (!isProduction()) {
    return config.featureFlagsKeyDev;
  }

  return isDevServer()
      ? config.featureFlagsKeyDev
      : isStgServer()
          ? config.featureFlagsKeyStg
          : isPilotServer()
              ? config.featureFlagsKeyPilot
              : config.featureFlagsKeyProd;
}

export function getApiBaseSegmentKey() {
  if (!isProduction() || isLocalServer()) {
    return config.featureFlagsKeyDev;
  }

  return isDevServer()
      ? config.featureFlagsKeyDev
      : isStgServer()
          ? config.featureFlagsKeyStg
          : config.featureFlagsKeyProd;
}

export const compareArrays = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

export const secondsToHms = (d) => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor(d % 3600 / 60);
  const s = Math.floor(d % 3600 % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  const mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
  const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";

  return hDisplay + mDisplay + sDisplay;
}

export const getTimeSpent = () => {
  const nowTime = dayjs();
  const loginTime = localStorage.getItem('login-timestamp');

  const secondDiff = nowTime.diff(loginTime, 'second');

  return secondsToHms(secondDiff);
}


export const formatErrorString = (obj) => {
  try {
    JSON.parse(obj);
  } catch (e) {
    return obj;
  }
  const currentObj = JSON.parse(obj);
  const errorArray = Object.keys(currentObj).map((key) => [
    key,
    currentObj[key],
  ]);
  let errorString = '';
  errorArray.forEach((err) => {
    errorString +=
        err[1] && Array.isArray(err[1])
            ? `<div class="-error-line">${err[0]}: ${err[1].join(' ')}</div>`
            : typeof err[1] === 'object'
                ? `<div class="-error-line">${err[0]}: ${Object.values(err[1]).join(
                    ' ',
                )}</div>`
                : `<div class="-error-line">${err[1]}</div>`;
  });

  return errorString;
};

export const formatSPLErrorString = (obj) => {
  try {
    JSON.parse(obj);
  } catch (e) {
    return obj;
  }
  const currentObj = JSON.parse(obj);
  const errorArray = Object.keys(currentObj).map((key) => [
    key,
    currentObj[key],
  ]);
  let errorString = '';
  errorArray.forEach((err) => {

    errorString +=
      err[1] && Array.isArray(err[1]) && err[1].length > 1
        ? err[1].map(e => `<div class="-error-line"><i class="warning-icon"></i><div>Error: ${err[0] !== 'message' ? `${err[0]}:` : ''} ${e}</div></div>`).join('')
        : err[1] && Array.isArray(err[1])
          ? `<div class="-error-line"><i class="warning-icon"></i><div>Error: ${err[0] !== 'message' ? `${err[0]}:` : ''} ${err[1]}</div></div>`
          : typeof err[1] === 'object'
            ? `<div class="-error-line"><i class="warning-icon"></i><div>Error: ${err[0]}: ${Object.values(err[1]).join(
              ' ',
            )}</div></div>`
            : `<div class="-error-line"><i class="warning-icon"></i><div>Error: ${err[1]}</div></div>`;
  });


  return errorString;
};

export const getBetaLabel = () => {
  return 'BETA'
}

export const formatPrice = (num, cost) => {
  const lookup = [
    { value: 1e6, symbol: "M" },
    { value: 1, symbol: "" },
  ];

  if (cost) {
    return (+num || 0).toFixed(3);
  }

  if ((+num > 0 && +num < 1) || (+num > 1 && +num < 1e6) || !cost) {
    return (+num || 0).toFixed(2);
  } else {
    return (+num || 0).toFixed(3);
  }

  const item = lookup.find(item => num >= item.value);

  if (!item) return "-";

  if (num % 1e6 === 0) {
    return (num / 1e6).toFixed(1) + item.symbol;
  }

  let integerPart = Math.floor(num / item.value);

  let decimalPart = (num % item.value) / item.value;
  decimalPart = Math.round(decimalPart * 1000) / 1000;

  if (decimalPart < 0.001) {
    return integerPart.toFixed(1) + item.symbol;
  }


  let numberOfZeros = 0;
  let temp = Math.floor(decimalPart * 1000);
  while (temp % 10 === 0 && temp !== 0) {
    numberOfZeros++;
    temp /= 10;
  }

  if (decimalPart === 0) {
    return integerPart + item.symbol;
  } else if (numberOfZeros > 0) {
    return integerPart + '.' + decimalPart.toFixed(3).slice(2, 2 + numberOfZeros + 1) + item.symbol;
  } else {
    return integerPart + '.' + decimalPart.toFixed(3).slice(2) + item.symbol;
  }
};

export const nonEmptyObject = (obj) => {
  return Object.entries(obj)
    .filter(([key, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }

      return value !== undefined && value !== '';
    })
    .reduce((acc, [key, value]) => {
      acc[key] = value;

      return acc;
    }, {});
};

export const addNoneOption = (array, count) => {
  const noneOption = { value: 'null', label: NONE };

  // Insert 'None' into the correct global position based on loaded data
  let insertIndex = array.findIndex(
    (option) => noneOption.label.localeCompare(option.label) < 0
  );
  const isNoneInside = array.some(
    (option) => option.label === NONE
  );

  if (insertIndex === -1 && array.length < count) {
    // If 'None' should be at the end, but not all data is loaded, delay insertion
    insertIndex = null;
  } else if (insertIndex === -1) {
    // If 'None' is alphabetically last and all data is loaded, add it to the end
    insertIndex = array.length;
  }

  if (!isNoneInside && insertIndex !== null) {
    array.splice(insertIndex, 0, noneOption);
  }

  return array;
};
