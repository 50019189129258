export const TEXTAREA_PLACEHOLDER =
    'Input ingredients here separated by commas or lines. Raw Materials cannot be ' +
    'nested underneath other Raw Materials.';

export const FORMULATION_RECOMMENDATION = [
  { name: 'Liquid', min: 'liquid_min', max: 'liquid_max', field: 'liquid' },
  { name: 'Oil', min: 'oil_min', max: 'oil_max', field: 'oil' },
  { name: 'Lotion', min: 'lotion_min', max: 'lotion_max', field: 'lotion' },
  { name: 'Cream', min: 'cream_min', max: 'cream_max', field: 'cream' },
  { name: 'Gel', min: 'gel_min', max: 'gel_max', field: 'gel' },
  { name: 'Wax', min: 'wax_min', max: 'wax_max', field: 'wax' },
  { name: 'Powder', min: 'powder_min', max: 'powder_max', field: 'powder' },
  { name: 'Aerosol', min: 'aerosol_min', max: 'aerosol_max', field: 'aerosol' },
  { name: 'pH', min: 'pH_min', max: 'pH_max', field: 'pH' },
];

export const INGREDIENT_TYPE = {
  ASSOCIATED_INCI: 'ASSOCIATED_INCI',
  INCI: 'INCI',
  IMPURITIES: 'IMPURITIES',
  ALLERGENS: 'ALLERGENS',
  INCIDENTAL: 'INCIDENTAL',
};

export const ASSOCIATED_INGREDIENT_TYPE = {
  INCI: 'inco',
  IMPURITIES: 'impurity',
  ALLERGENS: 'allergen',
  INCIDENTAL: 'incidental',
};

export const OPTIONS_SPAN = [
  {
    value: 'weeks',
    label: 'weeks',
  },
  {
    value: 'months',
    label: 'months',
  },
];

export const OPTIONS_UNITS = [
  {
    value: 'kg',
    label: 'kilograms',
  },
  {
    value: 'g',
    label: 'grams',
  },
  {
    value: 'lbs',
    label: 'pounds',
  },
];

export const CONSUMER_DB_TABS = {
  DETAILS: 'DETAILS',
  SIZE_AND_SHADES: 'SIZE_AND_SHADES',
  PRODUCT_VARIATIONS: 'PRODUCT_VARIATIONS',
  DOCUMENTS: 'DOCUMENTS',
}

export const RM_TABS = {
  MATERIAL: 'MATERIAL',
  SOURCING: 'SOURCING',
  TECHNICAL: 'TECHNICAL',
  REGULATORY: 'REGULATORY',
};

export const RM_TECHNICAL_OPTIONS = {
  IMPURITIES: 'Impurities',
  ALLERGENS: 'Allergens',
};

export const CERTIFICATIONS_HINT = 'You can auto-populate certifications by first uploading documents and ' +
  'selecting the corresponding certification document type.'

export const LIMITED_ACC_MESSAGE =
  'Your account is limited to certain policies. This type of report is not available for basic accounts. ' +
  'If you would like to be able to create these reports, please upgrade your access.';

export const CENTRIC_INTERNAL_STATUS = {
  active: 'Active',
  pending: 'Pending',
  inactive: 'Inactive',
}

export const TOOLTIP_DESCRIPTIONS = {
  [RM_TABS.MATERIAL]: {
    MORE_INFO: 'Insert a URL to the product page where you\'d like to direct Good Face users to order a sample, connect with a sales rep, or purchase this raw material.',
  },
  [RM_TABS.TECHNICAL]: {
    [RM_TECHNICAL_OPTIONS.IMPURITIES]: 'Ingredients in the impurities table will display in the formula editor as impurities. Impurities will not be calculated into the total formula composition.',
    [RM_TECHNICAL_OPTIONS.ALLERGENS]: 'Ingredients in the allergen table will not display in the formula editor, only in reporting outputs. If you’d like to see allergens in the formula editor, add them to INCI composition aswell'
  },
};


export const APIS_RM_ERRORS = {
  nameRequired: 'Missing data for required field.',
  shortName: 'Shorter than minimum length 1.',
  getMoreInfoRequired: 'Please, add a link.',
  getMoreInfoInvalid: 'Not a valid URL.',
}
