import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Loader from '../Loader';
import store from "../../store";
import { getAllPermissions } from "../../actions";
import { isFormulasPage } from "../../utils/isFormulasPage";
import { redirectToPage } from '../../utils/utils';

export const ID = '2053906';
export const VS = '6';

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedStorage: false,
      hasToken: '',
    };

    window.addEventListener('storage', (event) => {
      const credentials = JSON.parse(
        window.sessionStorage.getItem('user-info-for-verified'),
      );
      const sessionTimeStamp = JSON.parse(
        window.localStorage.getItem('sessionTimeStamp'),
      );
      const wasAutoLoggedOut = JSON.parse(localStorage.getItem('AUTO_LOGOUT'));

      if (event.key === 'REQUESTING_SHARED_CREDENTIALS') {
        this.setState({
          checkedStorage: true,
        });
      }

      if (event.key === 'REQUESTING_SHARED_CREDENTIALS' && credentials && sessionTimeStamp) {
        window.localStorage.setItem(
          'CREDENTIALS_SHARING',
          JSON.stringify(credentials),
        );
        window.localStorage.removeItem('CREDENTIALS_SHARING');
      }

      if (event.key === 'CREDENTIALS_SHARING' && !credentials && sessionTimeStamp) {
        window.sessionStorage.setItem('user-info-for-verified', event.newValue);
        this.setState({
          checkedStorage: true,
        });
      } else if (event.key === 'CREDENTIALS_SHARING') {
        const newValue = event.newValue?.length && JSON.parse(event.newValue);
        window.localStorage.removeItem('user-info-for-verified')

        if (
          newValue &&
          credentials &&
          newValue.access !== credentials.access
        ) {
          window.sessionStorage.setItem(
            'user-info-for-verified',
            event.newValue,
          );
        }
      } else {
        this.setState({
          checkedStorage: true,
        });
      }

      if (event.key === 'CREDENTIALS_FLUSH' && credentials) {
        window.sessionStorage.removeItem('email')
        window.sessionStorage.removeItem('user-info-for-verified');
        localStorage.removeItem('login');
        window.location.reload(false);
      }

      if (event.key === 'LOGIN_EVENT') {
        const parsedValue = JSON.parse(event.newValue);
        const tabId = parsedValue?.tabId;
        const currentTabId = sessionStorage.getItem('tabId');

        if (currentTabId !== tabId && !wasAutoLoggedOut) {
          const newCredentials = JSON.parse(localStorage.getItem('user-info-for-verified'));

          if (newCredentials) {
            window.sessionStorage.setItem('user-info-for-verified', JSON.stringify(newCredentials));
            window.location.reload();
          }
        } else {
          const newCredentials = JSON.parse(localStorage.getItem('user-info-for-verified'));
          const path = window.sessionStorage.getItem('path')
          const userEmail = window.sessionStorage.getItem('email')
          const loginEvent = JSON.parse(localStorage.getItem('LOGIN_EVENT'));
          const emailComparison  = userEmail === loginEvent?.email

          if (newCredentials) {
            window.sessionStorage.setItem('user-info-for-verified', JSON.stringify(newCredentials));
            this.setState({
              checkedStorage: true,
            });
            window.location.href = emailComparison && (path !== '/' && path?.length) ? path : '/fa/formulas';

          }
        }

        window.sessionStorage.removeItem('path')
      }
    });
  }

  componentDidMount() {
    this.checkUserToken();
    window.localStorage.removeItem('user-info-for-verified')
    window.addEventListener('storage', (e) => {
      if (e.key === 'user-info-for-verified') {
        const newValue = e.newValue?.length ? JSON.parse(e.newValue) : false;
        const oldValue = e.oldValue?.length ? JSON.parse(e.oldValue) : {};

        if (!newValue && newValue.user?.id !== oldValue.user?.id) {
          window.location.reload(false);
        }
      }
    });
    setTimeout(() => {
      this.setState({
        checkedStorage: true,
      });
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { location } = this.props;
    const { hasToken } = this.state;

    if (
      location.pathname !== prevProps.location.pathname &&
      hasToken.length &&
      location.pathname !== '/fa-profile/permission-editor' &&
      location.pathname !== '/fa-profile/management'
    ) {
      store.dispatch(getAllPermissions(false, isFormulasPage()));
    }
  }

  componentWillMount() {
    window.localStorage.setItem(
      'REQUESTING_SHARED_CREDENTIALS',
      Date.now().toString(),
    );
    window.localStorage.removeItem('REQUESTING_SHARED_CREDENTIALS');
  }
  checkUserToken = () => {
    const login = window.localStorage.getItem('login') === 'true';

    if (login) {
      let intervalId;
      const checkInterval = 100;
      const maxTime = 20000;

      const checkToken = () => {
        const user = JSON.parse(window.sessionStorage.getItem('user-info-for-verified'));
        const token = user?.access;

        if (token) {
          clearInterval(intervalId);
          this.setState({ hasToken:  token});
        }
      };

      intervalId = setInterval(checkToken, checkInterval);

      setTimeout(() => {
        clearInterval(intervalId);
        window.localStorage.removeItem('AUTO_LOGOUT')
        const user = JSON.parse(window.sessionStorage.getItem('user-info-for-verified'));
        const token = user?.access;

        if (!token) {
          redirectToPage('/', this.props.history)
        }
      }, maxTime);
    }
  };
  render() {
    const { location } = this.props;
    document.title = 'The Good Face Project';
    let scripts = document.getElementsByTagName('script');

    for (let i = 0; i < scripts.length; i++) {
      if (
        scripts[i].src &&
        scripts[i].src ===
          `https://static.hotjar.com/c/hotjar-${ID}.js?sv=${VS}`
      ) {
        scripts[i].remove();
      }
    }

    if (
      location.pathname.indexOf('fa/') !== -1 ||
      location.pathname.indexOf('fa-') !== -1
    ) {
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: ID, hjsv: VS };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }

    const { children } = this.props;
    const { checkedStorage, hasToken } = this.state;

    const login = window.localStorage.getItem('login') === 'true'

    return <div>{
      login && (
        location.pathname.indexOf('fa/') !== -1 ||
        location.pathname.indexOf('fa-') !== -1
      )
      ? (checkedStorage && hasToken.length)
        ? children
        : <Loader pg />
      : checkedStorage
        ? children
        : <Loader pg />
      // checkedStorage
      //   ? children
      //   : <Loader pg />
    }
    </div>;
  }
}

export default withRouter(Page);
